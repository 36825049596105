import { combineEpics } from 'redux-observable';
import { localeFormat } from '../../../../format/luxonToDisplayString';
import { phoneNumberFormatter } from '../../../../format/phoneNumberFormatter';
import { pluck } from '../../../../fp/object';
import { identity } from '../../../../fp/fp';
import { ofType } from '../../../../frp/operators/ofType';
import { castSchema } from '../../../../schema/schemaCaster';
import {
  withLatestFrom,
  filter,
  map,
  mergeMap,
  pluck as rxjsPluck,
  tap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { useReducer$ } from '../../../../hooks/useReducer$';
import { useEffect$ } from '../../../../hooks/useEffect$';
import { schemaGet } from '../../../../schema/schemaTypeBuilder';
import { useState, Fragment, useCallback } from 'react';
import { ClinicalDocumentButtons } from '../../../../components/ClinicalDocumentButtons';
import { toNowDate, toXsDateTimeString } from '../../../../g11n/ISODates';
import { Button, HeaderLabel, List } from '../../../';
import {
  Flexbox,
  GridLayout,
  IconButton,
  Label,
  AddCallLogPopup,
  MarkInErrorPopup,
} from '../../../../components';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { useXeRights } from '../../../../contexts/XeUserRightsContext';
import { useEnterprise } from '../../../../contexts/XeEnterpriseContext';
import { EDIT, REMOVE } from '../../../../icons';
import { formatStaffName } from '../../../../utils';
import { isDateAfter } from '../../../../utils/dates';
import { toDisplayDateFromISOString } from '../../../../g11n/displayDates';
import { useMenuNode } from '../../../../contexts/XeMenuNodeContext';
import { EMPTY_OBJECT } from '../../../../constants';
import useWorklistTemplateWrapperContext from '../../../WorklistUITemplateWrapper/hooks/useWorklistTemplateWrapperContext';
import { markInError } from 'services/call-logs/xe-call-logs-svc';
import FactXeCallLogSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog.json';
import XeOutreachItemSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$XeOutreachItems.json';
import '../styles.css';

const SHOULD_MARK_CALLLOG_IN_ERROR = 'action/shouldMarkCallLogInError';
const SHOULD_REFRESH = 'action/shouldRefresh';

const toFormattedDateString = (startDateTime, endDateTime) => {
  const formattedStart = toDisplayDateFromISOString(
    startDateTime,
    localeFormat.LONG
  );
  const formattedEnd = toDisplayDateFromISOString(
    endDateTime,
    localeFormat.TIME
  );
  return `${formattedStart} - ${formattedEnd}`;
};

const epics = [
  (action$, state$, { menuNode$ }) =>
    action$.pipe(
      ofType(SHOULD_MARK_CALLLOG_IN_ERROR),
      rxjsPluck('value'),
      withLatestFrom(
        menuNode$.pipe(
          rxjsPluck('requestFn'),
          filter((x) => x),
          map((fn) => fn())
        )
      ),
      mergeMap(([value, toRequest$]) =>
        markInError(
          value,
          { callLogId: value.CallLogID },
          toRequest$({ fullRequest: true })
        )
      ),
      rxjsPluck('results'),
      map((value) => ({
        type: SHOULD_REFRESH,
        value,
      }))
    ),
];

const epic = combineEpics(...epics);

export const CallLogDetailsRender = (props) => {
  const { dataElementName = '', data = EMPTY_OBJECT, onRefresh } = props;
  const {
    Comments,
    CallToName,
    CallToPhone,
    IsInbound,
    StartDateTime,
    EndDateTime,
    PlacerID,
    LockDateTime,
    IsInError,
    XeOutreachItems = [],
    IPID,
    XeEnterpriseData,
  } = data;

  const menuNode = useMenuNode();
  const epicWithDeps = useCallback(
    (action$, state$) => epic(action$, state$, { menuNode$: of(menuNode) }),
    [menuNode]
  );
  const [, dispatch, action$] = useReducer$(identity, epicWithDeps);

  const labels = useXeLabels();
  const { ALLOW_LOCKED, ALLOW_NONOWNER, MARKINERROR_CALLLOG } = useXeRights();
  const { userData: { ResourceID: loggedInResourceId } = {} } = useEnterprise();
  const {
    worklistVariationData: { ivid },
  } = useWorklistTemplateWrapperContext();

  const placerIdCredentials = pluck('Credentials')(PlacerID);
  const StaffID = pluck('StaffID')(PlacerID);
  const enterpriseId = pluck('EnterpriseID')(XeEnterpriseData);
  const isLoggedInUserCreator = loggedInResourceId === StaffID;

  const isInFuture = LockDateTime
    ? isDateAfter(toXsDateTimeString(toNowDate()))(LockDateTime)
    : undefined;

  const canEdit =
    !IsInError &&
    (isLoggedInUserCreator || ALLOW_NONOWNER) &&
    (!LockDateTime || isInFuture || (!isInFuture && ALLOW_LOCKED));

  const canMarkInError = MARKINERROR_CALLLOG;

  const [showCallLog, setShowCallLog] = useState(false);
  const [showMarkInError, setShowMarkInError] = useState(false);

  useEffect$(
    () =>
      action$.pipe(
        ofType(SHOULD_REFRESH),
        tap(() => onRefresh(data)),
        tap(() => setShowMarkInError(false))
      ),
    [action$, data, onRefresh, setShowMarkInError]
  );

  return (
    <>
      <Flexbox
        dataElementName={
          !!dataElementName
            ? `${dataElementName}__activityAssetRender`
            : 'activityAssetRender'
        }
        direction="column"
        data-component-name="Activity-Asset-Render"
      >
        <GridLayout
          templateColumns="max-content auto auto"
          className="padding-all-medium"
        >
          <Label
            dataElementName={
              !!dataElementName ? `${dataElementName}__date` : 'date'
            }
            descriptor={labels.Date}
            className="call-log-details-render__display call-log-details-render__label--column2-span2"
          >
            {toFormattedDateString(StartDateTime, EndDateTime)}
          </Label>
          <Label
            dataElementName={
              !!dataElementName ? `${dataElementName}-phone` : 'phone'
            }
            descriptor={labels.Phone}
            className="call-log-details-render__display call-log-details-render__label--column2-span2"
          >
            {phoneNumberFormatter(CallToPhone)}
          </Label>
          <Label
            dataElementName={
              !!dataElementName ? `${dataElementName}__direction` : 'direction'
            }
            descriptor={labels.Direction}
            className="call-log-details-render__display call-log-details-render__label--column2-span2"
          >
            {IsInbound ? labels.Incoming : labels.Outgoing}{' '}
          </Label>
          <Label
            dataElementName={
              !!dataElementName ? `${dataElementName}__outcome` : 'outcome'
            }
            descriptor={labels.Outcome}
            className="call-log-details-render__display call-log-details-render__label--column2-span2"
          >
            {pluck('OutcomeID', 'CodedComment')(data) || labels.None}
          </Label>
          <Label
            dataElementName={
              !!dataElementName ? `${dataElementName}__place` : 'place'
            }
            descriptor={labels.Caller}
            className="call-log-details-render__display call-log-details-render__label--column2-span2"
          >
            {formatStaffName(PlacerID)}
            {placerIdCredentials ? ` (${placerIdCredentials})` : null}
          </Label>
          <Label
            dataElementName={
              !!dataElementName
                ? `${dataElementName}__callToName`
                : 'callToName'
            }
            descriptor={labels.CallWith}
            className="call-log-details-render__display call-log-details-render__label--column2-span2"
          >
            {CallToName}
          </Label>
          <Label
            dataElementName={
              !!dataElementName ? `${dataElementName}__followUp` : 'followUp'
            }
            descriptor={labels.FollowUp}
            className="call-log-details-render__display call-log-details-render__label--column2-span2"
          >
            {pluck('XeWaitList', 'WaitListSubCategoryID', 'Name')(data) ||
              labels.None}
          </Label>
          <Label
            dataElementName={
              !!dataElementName ? `${dataElementName}__comment` : 'comment'
            }
            descriptor={labels.Comment}
            className="call-log-details-render__display call-log-details-render__label--column2-span2"
          >
            {Comments}
          </Label>
          <Flexbox
            style={{ gridColumn: '1 / -1' }}
            justifyContent="space-between"
          >
            {canEdit && (
              <IconButton
                dataElementName={
                  !!dataElementName ? `${dataElementName}__edit` : 'edit'
                }
                look="default"
                icon={EDIT}
                description={labels.Edit}
                onClick={() => setShowCallLog(true)}
              />
            )}
            {canMarkInError && (
              <IconButton
                dataElementName="callLogDetails__markInError"
                look="default"
                onClick={() => setShowMarkInError(true)}
                description={labels.MarkInError}
                icon={REMOVE}
              />
            )}
          </Flexbox>
        </GridLayout>
        {XeOutreachItems.length ? (
          <Flexbox direction="column" className="padding-all-small">
            <HeaderLabel>{labels.OutreachItems}</HeaderLabel>
            <List
              data={XeOutreachItems}
              component={Fragment}
              renderItem={({ item }) => {
                const visitAssessmentId = schemaGet(
                  XeOutreachItemSchema,
                  'VisitAssessmentID',
                  item
                );
                const status = schemaGet(
                  XeOutreachItemSchema,
                  'VisitAssessmentID.Status',
                  item
                );
                const name = schemaGet(
                  XeOutreachItemSchema,
                  'VisitAssessmentID.AssessmentID.Name',
                  item
                );
                const ipid = schemaGet(
                  XeOutreachItemSchema,
                  'VisitAssessmentID.IPID',
                  item
                );
                return (
                  <Flexbox alignItems="center" justifyContent="space-between">
                    <ClinicalDocumentButtons
                      viewButtonComponent={Button}
                      viewButtonComponentProps={{
                        look: 'hyperlink',
                        children: <Label>{name}</Label>,
                      }}
                      showDownload={false}
                      ipid={ipid}
                      data={visitAssessmentId}
                      readOnly={!canEdit}
                      labels={labels}
                      menuNode={menuNode}
                      enterpriseId={enterpriseId}
                    />
                    <Label>{status}</Label>
                  </Flexbox>
                );
              }}
            />
          </Flexbox>
        ) : null}
      </Flexbox>
      {showCallLog && (
        <AddCallLogPopup
          ivid={ivid}
          initialCallLog={castSchema(FactXeCallLogSchema)(data)}
          patient={IPID}
          onSave={(value) => {
            onRefresh(value);
            setShowCallLog(false);
          }}
          onClose={() => setShowCallLog(false)}
        />
      )}
      {showMarkInError && (
        <MarkInErrorPopup
          onConfirm={(reason) => {
            dispatch({
              type: SHOULD_MARK_CALLLOG_IN_ERROR,
              value: {
                CallLogID: data.CallLogID,
                IsInError: true,
                ErrorDescription: reason,
              },
            });
          }}
          onClose={() => setShowMarkInError(false)}
        />
      )}
    </>
  );
};
